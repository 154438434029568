import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData, setStorageData } from '../../../framework/src/Utilities';
import { getTranslationConfig } from '../../../components/src/helpers';
import moment from "moment";

// Customizable Area Start
const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    fromCustomerProfile?: boolean;
    customStyles?: object;
    heading?: string;
    breadcrumbs?: string[];
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    coupons: Coupon[];
    id: any;
    error: string | null;
    price: number;
    showWarning: boolean;
    warningMinCartValue: number | null;
    warningCouponId: string | null;
    bookingId: number;
    isPreviewMode: boolean;
    catelogueId: string;
    loading:boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any
    // Customizable Area End
}

export interface Coupon {
    id: number;
    title: string;
    description: string | null;
    code: string;
    discount_type: string;
    discount: string;
    valid_from: string;
    valid_to: string;
    min_cart_value: string;
    max_cart_value: string | null;
    created_at: string;
    updated_at: string;
    account_id: number;
    visibility: boolean;
    usage_type: string;
    usage_limit: number | null;
    arabic_title: string | null;
    arabic_description: string | null;
}

class CouponsController extends BlockComponent<Props, S, SS> {
    apiCouponsDetails = "";
    applyCouponsDetails = "";
    currency = '';
    apiAllCouponsByiID = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        (async () => {
            this.currency = await getStorageData('currency');
        })();

        this.state = {
            coupons: [],
            id: null,
            error: null,
            price: 0,
            showWarning: false,
            warningMinCartValue: null,
            warningCouponId: null,
            bookingId: 0,
            isPreviewMode: false,
            catelogueId: "",
            loading:true
        };
        this.goToBookingsPage = this.goToBookingsPage.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.setState({ loading: true });
        const timeSlotInfo = await getStorageData('selectedTimeSlotPrice');
        if (timeSlotInfo) {
            let slotInfo;
            try {
                slotInfo = JSON.parse(timeSlotInfo);
            } catch (e) {
                slotInfo = timeSlotInfo;
            }
            this.setState({
                price: parseFloat(slotInfo.price),
                catelogueId: slotInfo.catelogueId
            });
        }
        const { fromCustomerProfile } = this.props;

        if (fromCustomerProfile) {
            await this.getAllCoupons();
        } else {
            await this.getCouponsDetails();
        }

        const urlParams = new URLSearchParams(window.location.search);
        const idParam = urlParams.get('id');
        const isPreviewMode = idParam === 'preview';
        const id = isPreviewMode ? null : Number(idParam);

        this.setState({
            bookingId: id || 0,
            isPreviewMode
        });
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);

        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorResponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (responseJson) {
            if (apiRequestCallId === this.applyCouponsDetails) {
                await setStorageData("coupon_response", JSON.stringify(responseJson.data));
            }
            if (apiRequestCallId === this.apiCouponsDetails) {
                this.setState({ coupons: responseJson, loading: false  })
            }
            if (apiRequestCallId === this.apiAllCouponsByiID) {
                const coupons = responseJson.info.data.map((coupon: { attributes: any; }) => coupon.attributes);
                this.setState({ coupons: coupons, loading: false })
            }
        }

        if (errorResponse) {
            const { t } = getTranslationConfig();
            let errorMessage = t['coupons-failed-fetch'];
            if (errorResponse.message) {
                errorMessage = errorResponse.message;
            }
            this.setState({ error: errorMessage });
        }
        // Customizable Area End
    }

    async getCouponsDetails() {
        try {
            const authToken = await getStorageData('authToken');
            const catalogue_id = this.state.catelogueId;

            const headers = {
                "Content-Type": "application/json",
                "token": authToken
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            if (!this.apiCouponsDetails) {
                this.apiCouponsDetails = requestMessage.messageId;
            }

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.addCouponsCode}?catalogue_id=${catalogue_id}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.userDetailsApiMethodType
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        } catch (error) {
        }
    }

    async getAllCoupons() {
        const authToken = await getStorageData('authToken');
        const headers = {
            "Content-Type": "application/json",
            "token": authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiAllCouponsByiID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getAllCouponsEndPoint}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.userDetailsApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    getLogicalValue(firstValue: string | null, secondValue: React.ReactNode, thirdValue: React.ReactNode): React.ReactNode | null {
        return firstValue !== null && firstValue !== '' ? secondValue : thirdValue;
    }

    applyCouponHandler = async (minCartValue: string, couponId: string, code: string, discount: string, discount_type: string) => {
        const { price, isPreviewMode } = this.state
        const minCartValueNumber = parseFloat(minCartValue);

        if (price < minCartValueNumber) {
            this.setState({
                showWarning: true,
                warningMinCartValue: minCartValueNumber,
                warningCouponId: couponId
            });
        } else {
            this.setState({
                showWarning: false,
                warningMinCartValue: null,
                warningCouponId: null
            });
            try {
                const couponData = { discount, discount_type, couponId };
                await setStorageData('appliedCoupon', JSON.stringify(couponData))
                const id = isPreviewMode ? 'preview' : this.state.bookingId;
                this.goToBookingsPage(id);
            } catch (error) {
            }
        }
    };

    goToBookingsPage = (id: number | string) => {
        const url = `/BookingDetails/${id}`;
        const message: Message = new Message(
            getName(MessageEnum.NavigationBookingDetails)
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
        window.location.href = url;
    }

    filterCurrentCoupons(coupons: any[]) {
        const currentDate = moment();
        return coupons.filter((coupon) => {
            return moment(coupon.valid_from, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(currentDate)
                && moment(coupon.valid_to, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(currentDate);
        });
    }

}

export default CouponsController;
