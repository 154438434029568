import React from "react"
// Customizable Area Start
import { Box, Button, Card, CardMedia, CircularProgress, Grid, Typography } from '@material-ui/core';
import { AppHeader } from '../../../components/src/AppHeader.web';
import {

    img962c7d73186b2cd6f245df742bac938b,

} from './assets';
import { AppFooter } from '../../../components/src/AppFooter.web';
import LandingPageController, { S } from './LandingPageController';
import { TypographyView } from "./LandingPage.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { getTranslationConfig } from '../../../components/src/helpers';

interface Props {
    navigation: any;
    id: string;
}

export default class SpasByCountry extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        const selectedCountry = await getStorageData('selectedCountry');
        if (selectedCountry) {
            this.setState({ selectedCountry });
            await this.getSpasBasedOnCountry(selectedCountry);
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    async componentWillUnmount(): Promise<void> {
        localStorage.removeItem('selectedCountry');
    }

    async componentDidUpdate(prevProps: Props, prevState: S) {
        if (prevState.selectedCountry !== this.state.selectedCountry && this.state.selectedCountry) {
            this.getSpasBasedOnCountry(this.state.selectedCountry);
            this.forceUpdate();
        }
    }

    navigationMap = {
        Home: 'LandingPage',
    };

    render() {
        const { t, dir, isArabic } = getTranslationConfig();
        const profileResponse = this.state.profileResponse

        return (
            <TypographyView>
                <Box style={{ backgroundColor: "#E8F6F4" }}>
                    <AppHeader
                        onProfileClick={this.handleProfileClick}
                        forceUpdate={this.forceUpdate.bind(this)}
                        context={this}
                    />
                    <Box>
                        <Box sx={{ px: 20, my: 5 }} dir={dir}>
                            <Typography style={{
                                fontFamily: "Ubuntu",
                                fontWeight: 500,
                                fontSize: "40px",
                                lineHeight: "64px",
                                color: "#0F172A",
                                marginBottom: "40px"
                            }}>{t['spas-by-country-main-heading-title']} {this.state.selectedCountry}</Typography>
                            <Box>
                                {this.state.subCatLoading ? (
                                    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                                        <CircularProgress style={{ color: '#398378' }} />
                                    </Box>
                                ) : (
                                    <Grid container spacing={4} justifyContent="center">
                                        {profileResponse?.data?.map((item, index) => (
                                            <Grid item xs={12} sm={6} md={4} key={index}>
                                                <Card style={{
                                                    height: '345px',
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    overflow: 'hidden'
                                                }}>
                                                    <CardMedia
                                                        component="img"
                                                        image={item.profile_photo ? item.profile_photo.url : img962c7d73186b2cd6f245df742bac938b}
                                                        title={item.attributes.full_name}
                                                        style={{ width: '345px', height: '345px', objectFit: 'cover' }}
                                                    />
                                                    <Box
                                                         style={{
                                                            position: 'absolute',
                                                            bottom: 20,
                                                            left: isArabic ? 'unset' : 20,
                                                            right: isArabic ? 20 : 'unset',
                                                            display: 'flex',
                                                            flexDirection: "column",
                                                            color: '#fff',
                                                            textAlign: isArabic ? 'right' : 'left', 
                                                        }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontFamily: 'Ubuntu',
                                                                fontWeight: 700,
                                                                fontSize: '24px',
                                                                lineHeight: '32px',
                                                                letterSpacing: '-0.5px',
                                                                marginBottom: "20px",
                                                                left: isArabic ? "200px" : "0px"
                                                            }}
                                                        >
                                                            {item.attributes.full_name}
                                                        </Typography>
                                                        <Button
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                            style={{
                                                                backgroundColor: '#E8F6F4',
                                                                color: '#E8F6F4',
                                                                fontSize: '16px',
                                                                fontWeight: 500,
                                                                height: '44px',
                                                                borderRadius: '8px',
                                                                textTransform: 'capitalize',
                                                                width: "100px",
                                                                cursor: "pointer",
                                                             
                                                            }}
                                                            onClick={() => this.goToVenue(item.id)}
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    fontFamily: 'Ubuntu',
                                                                    fontWeight: 700,
                                                                    fontSize: '16px',
                                                                    lineHeight: '24px',
                                                                    color: '#398378',
                                                                   
                                                                }}
                                                            >
                                                                {t['spas-by-country-button-text']}
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <AppFooter navigation={this.props.navigation} />
                </Box>
            </TypographyView>
        );

    }
}
