import React from "react";
import { Box, Typography, MobileStepper, ThemeProvider, Container } from "@material-ui/core";
import { RecoveryPasswordView, TypographyView, styles, theme } from "../PasswordRecovery.web";
import { headerLogoImg } from "../assets";

export const PasswordRecoveryLayout = ({ children }: { children: React.ReactNode }) => (
    <ThemeProvider theme={theme}>
        <Container style={styles.paperContainer}>
            <Box className="passwordRecoveryView" sx={{
                display: "flex", flexDirection: "row",
                justifyContent: "space-between", alignItems: "center", mx: 2, px: 10
            }}>
                <LeftSideContent />
                <RecoveryPasswordView sx={{ mt: 5 }}>
                    <FormHeader />
                    <Box className="mainView">
                        {children}
                    </Box>
                </RecoveryPasswordView>
            </Box>
        </Container>
    </ThemeProvider>
);

const LeftSideContent = () => (
    <Box width="45%">
        <TypographyView>
            <Typography className="heading">Welcome to SPAroof</Typography>
            <Typography className="description">SPAroof, your premier destination for spa enthusiasts worldwide. We're thrilled to invite you to join our platform and showcase your spa to our community of wellness seekers.</Typography>
        </TypographyView>
    </Box>
);

export const FormHeader = () => (
    <Box className="formHeader">
        <img src={`${headerLogoImg}`} className="logo" alt="Logo" />
    </Box>
);