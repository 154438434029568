import React from 'react';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import { getTranslationConfig } from '../../../../components/src/helpers';
import CloseIcon from '@material-ui/icons/Close';
import { TypographyView } from '../../../ordermanagement1/src/styledFont';
import UserProfileBasicController, {
    Props,
} from '../UserProfileBasicController';

export default class LogoutBack extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    handleCloseModal() {
        this.props.setOpenLogoutModal?.(false);
        window.history.pushState(null, "", window.location.pathname);
    }

    render() {
        const { t } = getTranslationConfig();
        const { openLogoutModal, setOpenLogoutModal } = this.props;

        if (!setOpenLogoutModal) {
            return null;
        }

        return (
            <Modal
                data-test-id='login-modal'
                open={Boolean(openLogoutModal)}
                onClose={this.handleCloseModal.bind(this)}
            >
                <TypographyView>
                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: '45%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: '#FFFFFF',
                            borderRadius: '8px 8px 32px 8px',
                            outline: 'none',
                            maxWidth: '80%',
                            minWidth: '20rem',
                        }}
                    >
                        <Box p={2} borderBottom='1px solid #E2E8F0'>
                            <Box
                                display='flex'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <CloseIcon
                                    data-test-id='login-close-btn'
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 'auto',
                                    }}
                                    onClick={this.handleCloseModal.bind(this)}
                                />
                            </Box>
                            <Box px={5} py={2}>
                                <Typography
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: 700,
                                        marginBottom: '8px',
                                    }}
                                >
                                    Are you sure you want to exit?
                                </Typography>
                            </Box>
                        </Box>
                        <Box p={3}>
                            <Box display='flex' justifyContent='flex-end' width='100%'>
                                <Button
                                    data-test-id='refuse-booking-cancel-btn'
                                    type='button'
                                    variant='contained'
                                    className='agreeButton'
                                    style={{
                                        backgroundColor: '#D9EEEB',
                                        fontWeight: 700,
                                        lineHeight: '24px',
                                        fontFamily: 'Ubuntu',
                                        fontSize: '16px',
                                        width: '190px',
                                        color: '#398378',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                    }}
                                    onClick={this.handleCloseModal.bind(this)} 
                                >
                                    Cancel
                                </Button>
                                <Button
                                    data-test-id='login-btn'
                                    variant='contained'
                                    color='primary'
                                    className='agreeButton'
                                    style={{
                                        fontWeight: 700,
                                        lineHeight: '24px',
                                        fontFamily: 'Ubuntu',
                                        fontSize: '16px',
                                        width: '190px',
                                    }}
                                    onClick={() => this.logout()}
                                >
                                    Logout
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </TypographyView>
            </Modal>
        );
    }
}