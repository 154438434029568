import React from "react";

//Customizable Area Start
import { Box, Button, Typography, createTheme, styled } from "@material-ui/core";
import { backgroundImg } from "./assets";
import WebForgotPasswordController, { Props } from "./WebForgotPasswordController";
import { PasswordRecoveryLayout } from "./components/PasswordRecoveryLayout";

export const styles = {
  paperContainer: {
    backgroundImage: `url(${backgroundImg})`,
    width: '100%',
    height: '1920px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: 0,
    margin: 0,
    maxWidth: '100%',
    boxSizing: "border-box" as const,
  }
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export const RecoveryPasswordView = styled(Box)({
  width: '47%',
  borderRadius: '24px 24px 0px 0px',
  background: 'var(--Neutrals-Cool-gray-50, #F8FAFC)',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  height: '600px',
  '& .mainView': {
    padding: '40px',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    background: 'var(--Neutrals-Cool-gray-50, #F8FAFC)'
  },
  '& .formHeader': {
    'display': 'flex',
    padding: '27px 172px 26px 172px',
    borderRadius: '24px 24px 0px 0px',
    background: 'var(--Primary, #398378)'
  },
  '& .logo': {
    width: '176px',
    height: '72px'
  },
  '& .btnStyle': {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 10px 0px",
  },

  '& .button': {
    width: '390px',
    background: '#398378',
    padding: '16px',
    textTransform: 'none',
    color: '#FFF',
  },
  '& .MuiButton-textPrimary': {
    color: '#FFFFF'
  },
  '& .MuiBox-root-19': {
    color: '#1B5950 !important'
  },
  '& .MuiBox-root-14': {
    color: '#334155 !important'
  },
  '& .PrivateSwitchBase-root-18': {
    paddingLeft: '0px'
  },
  '& .forgotPwdText': {
    fontSize: "14px",
    color: "#1B5950",
    fontWeight: "400",
    marginTop: "5px",
  },
  '& .remember': {
    fontSize: "14px",
    color: "#334155",
    fontWeight: "400",
    marginTop: "5px",
  },
  '& .accountText': {
    fontSize: '16px',
    color: '#0F172A',
    fontWeight: 400,
    fontFamily: 'Ubuntu'

  },
  '& .inputeStyle': {
    borderRadius: '8px',
    border: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
    background: 'var(--Basic-White, #FFF)'
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #398378 !important'
  },
  '& .signUp': {
    fontSize: '16px',
    color: '#145249',
    fontWeight: 'bold',
    fontFamily: 'Ubuntu',
    paddingLeft: '5px'

  },
  '& .MuiSnackbarContent-root': {
    background: '#FEE2E2',
    color: '#DC2626',
    borderLeft: '4px solid #DC2626',
    borderRadius: '4px',
    width: '405px',
  },
  '& .MuiSnackbar-anchorOriginBottomCenter': {
    transform: 'translateX(0%)',
    position: 'inherit',
    marginTop: '20px'
  },
  '& .MuiPaper-root': {
    background: '#D9EEEB',
    width: '180px',
    left: '860px'
  },
  '& .PrivateSwitchBase-root-25': {
    paddingLeft: '0px'
  },
  '& .passwordRecoveryView': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'space-between',
  },
  '& .testLabel': {
    paddingTop: '10px',
    paddingBottom: '10px',
    fontWeight: 700,
    fontSize: '16px',
    color: '#334155',
    fontFamily: 'Ubuntu',
  },
  '& .testStar': {
    color: '#DC2626',
    fontSize: '26px',
    marginLeft: "5px",
  },
  // MuiOutlinedInput-root.Mui-focused MuiOutlinedInput-notchedOutline

});


export const TypographyView = styled(Typography)({
  '& .description': {
    fontSize: '16px',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    color: '#FFF'
  },
  '& .txtErrorMsg': {
    color: '#DC2626',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Ubuntu',
    paddingTop: '5px'
  },
  '& .heading': {
    fontSize: '36px',
    fontFamily: 'Ubuntu',
    fontWeight: 700,
    color: '#FFF'
  },

  '& .title': {
    color: `var(--Basic-Black, #000)`,
    fontFamily: 'Ubuntu',
    fontSize: '24px',
    fontWeight: 700,
  },
  '& .link': {
    color: '#0F172A',
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    fontWeight: 400,
    paddingTop: '5px'
  },
  '& .emailTitle': {
    color: '#0F172A',
    fontFamily: 'Ubuntu',
    fontSize: '18px',
    fontWeight: 700,
    paddingTop: '20px'
  },
  '& .readAndAgree': {
    color: '#0F172A',
    fontFamily: 'Ubuntu',
    fontSize: '12px'
  },
  '& .termsAndCond': {
    color: '#50C',
    fontFamily: 'Ubuntu',
    fontSize: '12px',
    paddingRight: '2px',
    textDecoration: 'underline'
  }
});

//Customizable Area End

export default class RecoveryPassword extends WebForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start

    //Customizable Area End
  }


  render() {

    const { resetEmail } = this.state

    return (
      <PasswordRecoveryLayout>
        <TypographyView>
          <Typography className="title">{'Password Recovery'}</Typography>
          <Typography className="emailTitle">{'Check your email'}</Typography>
          <Typography className="link">{`We have sent a password reset link to ${resetEmail}`}</Typography>
        </TypographyView>
        <Box className="btnStyle">
          <Button
            data-test-id={"btnResendEmail"}
            className="button"
            onClick={() => this.ResendPasswordButtonClick(resetEmail)}
          >
            Resend E-mail
          </Button>
        </Box>
        <TypographyView>
          <Box sx={{ flexDirection: 'row', display: 'flex' }} pt={2} pb={2}>
            <Typography className="account"> {'Back to'} </Typography>
            <Typography data-test-id="recoveryLogin" className="signUp" onClick={()=>this.goToLogin()}> {'Log in'}</Typography>
          </Box>
        </TypographyView>
      </PasswordRecoveryLayout>
    );
  }
}